import React from 'react';
import { compiler } from 'markdown-to-jsx';

import Link from '@mui/material/Link';
import MdCopyAll from '@mui/icons-material/CopyAll';
import { moduleConfig } from '../Module/constants.ts';

const copyFullConversationButton = (fullConversation) => {
    const copyFullConversation = () => {
        navigator.clipboard.writeText(JSON.stringify(fullConversation));
    };

    return (
        <Link
            onClick={copyFullConversation}
            sx={{
                'display': 'flex',
                'alignItems': 'center',
                'cursor': 'pointer',
                'color': 'text.secondary',
                'width': 'fit-content',
                '&:hover': {
                    color: 'text.primary',
                },
                'mt': 4,
            }}
        >
            <MdCopyAll sx={{ mr: 1 }} />
            Copy full prompt
        </Link>
    );
};

// Convert markdown text generated by LLM into React/HTML components
export const formatLLMGeneratedText = (text, conversation = [], onClickFilterText = undefined) => {
    const components = compiler(text);

    const regexPatterns = {
        Bioproject: /(?![^{}]*\}\})PRJ\w+/g,
        Filters: /\{\{[^}]+\}\}/g,
    };

    const additionalFormatting = (str) => {
        if (!str) return str;

        // Collect all matches from both patterns
        const matches = [];
        for (const [type, regex] of Object.entries(regexPatterns)) {
            for (const match of str.matchAll(regex)) {
                matches.push({ type, match, startIndex: match.index, endIndex: match.index + match[0].length });
            }
        }

        // Sort matches by their start index
        matches.sort((a, b) => a.startIndex - b.startIndex);

        const finalComponents = [];
        let currentIndex = 0;

        // Process each match sequentially
        for (const { type, match, startIndex, endIndex } of matches) {
            const beforeMatch = str.slice(currentIndex, startIndex);
            currentIndex = endIndex;

            // Add text before the match
            if (beforeMatch) {
                finalComponents.push(<React.Fragment key={`before-${currentIndex}`}>{beforeMatch}</React.Fragment>);
            }

            // Process the match based on its type
            if (type === 'Filters') {
                const cleanMatch = match[0];
                const moduleKey = cleanMatch.slice(2, -2).split(':')[0].trim().toLowerCase();
                const moduleKeys = Object.keys(moduleConfig).map((key) => key.toLowerCase());

                if (!moduleKeys.includes(moduleKey)) continue;

                finalComponents.push(
                    <Link
                        key={`filter-${currentIndex}`}
                        href='#'
                        onClick={(e) => {
                            e.preventDefault();
                            if (onClickFilterText) {
                                const filterText = cleanMatch.slice(2, -2);
                                onClickFilterText(filterText);
                            }
                        }}
                    >
                        {cleanMatch.slice(2, -2)}
                    </Link>,
                );
            } else if (type === 'Bioproject') {
                const cleanMatch = match[0];
                finalComponents.push(
                    <Link
                        key={`bioproject-${currentIndex}`}
                        href={`https://www.ncbi.nlm.nih.gov/bioproject/${cleanMatch}`}
                        target='_blank'
                    >
                        {cleanMatch}
                    </Link>,
                );
            }
        }

        // Add any remaining text after the last match
        const afterMatch = str.slice(currentIndex);
        if (afterMatch) {
            finalComponents.push(<React.Fragment key={`after-${currentIndex}`}>{afterMatch}</React.Fragment>);
        }

        return finalComponents;
    };

    // This is needed to process nested components returned from markdown-to-jsx
    const formatNestedChildComponents = (component) => {
        if (typeof component === 'string') {
            return additionalFormatting(component);
        } else if (React.isValidElement(component)) {
            const processedChildren = React.Children.map(component.props.children, formatNestedChildComponents);
            const clonedComponent = React.cloneElement(component, {
                ...component.props,
                children: processedChildren,
            });
            return clonedComponent;
        } else if (Array.isArray(component)) {
            return component.forEach(formatNestedChildComponents);
        } else {
            return component;
        }
    };

    const children = components.props.children;
    const finalComponents = React.Children.map(children, formatNestedChildComponents);

    // add copy full conversation button
    if (conversation.length > 0) {
        finalComponents.push(copyFullConversationButton(conversation));
    }

    // return components
    return finalComponents;
};
